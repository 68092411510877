/* Basic Styling */
body {
    font-family: Arial, sans-serif;
    margin: 0;
}

header {
    background-color: rgb(255, 255, 255); /* Header background color */
    padding: 1px 0; /* Padding around the header */
}

/* .container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */

.logo {
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

/* Navigation Styling */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: rgb(251, 251, 251);
}

.navbar ul {
    list-style: none;
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.nav-link {
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background: none; /* Remove background */
    color: #333; /* Text color */
    padding: 0.5rem 1rem; /* Padding */
    font-size: 1rem; /* Font size */
    text-decoration: none; /* Remove underline */
}

.nav-link:hover,
.nav-link:focus {
    color: #0976b0; /* Color on hover/focus */
    text-decoration: underline; /* Optional: underline on hover/focus */
}

/* Navbar Brand Styling */
.navbar-brand {
    background: none; /* Remove background */
    border: none; /* Remove border */
    color: #333; /* Text color */
    font-size: 1.5rem; /* Font size */
    padding: 0; /* Padding */
    margin: 0; /* Margin */
    cursor: pointer; /* Pointer cursor */
    text-decoration: none; /* Remove underline */
}

.navbar-toggler {
    border: none; /* Remove border */
}

.navbar-toggler-icon {
    background: transparent;
    border: none;
    position: relative;
    display: inline-block;
    width: 30px;
    height: 25px;
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after,
.navbar-toggler-icon div {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #333; /* Set color */
    transition: all 0.3s ease;
}

.navbar-toggler-icon::before {
    top: 0;
}

.navbar-toggler-icon div {
    top: 50%;
    transform: translateY(-50%);
}

.navbar-toggler-icon::after {
    bottom: 0;
}


/* Header Top Styling */
.header_top {
    background: #007bff; /* Background color for top section */
    color: white;
    padding: 0.5rem 0;
    text-align: center;
}

.contact_nav a {
    color: white; /* Text color */
    text-decoration: none; /* Remove underline */
    margin-right: 1rem; /* Space between links */
}

.contact_nav a:hover {
    text-decoration: underline; /* Optional: underline on hover */
}

.hero_area {
  position: relative;
  background-color: #d1e3ff;
}

.header_section .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.header_section .header_top {
  padding: 15px 0;
  background-color: #000000;
}

.header_section .header_top .contact_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.header_section .header_top .contact_nav a {
  color: #ffffff;
}

.header_section .header_top .contact_nav a i {
  color: #ff8a1d;
}

.header_section .header_bottom {
  padding: 10px 0;
}

/* Responsive Layout */
@media (max-width: 768px) {
    .navbar-nav {
        flex-direction: column; /* Stack items vertically */
    }

    .navbar-nav .nav-item {
        margin: 0; /* Remove margin for vertical layout */
    }
}

