/* Set the body background to white to ensure the page background is white */
body {
  background-color: white;
}

/* Ensure the background color of the entire page is white */
.hero_area {
  background-color: white; /* Set background color to white */
  margin-top: 50px; /* Add space between the header and the section */
}

/* Optional: Add padding and background to the about section */
.about_section {
  background-color: white; /* Ensure the about section has a white background */
  padding-top: 50px; /* Add space at the top of the section */
}

/* Other styles remain the same */
.about {
  padding: 2rem 1rem;
  text-align: center;
}

.about-content {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.service {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 5px;
  width: 30%;
}

.static-image {
  width: 45%; /* Increase logo size to 30% of the available width */
  height: auto;
  border-radius: 8px;
  margin-top: 20px; /* Add space between image and text */
}


.detail-box h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem; /* More space below the header */
}

/* For responsiveness: Ensure it still looks good on smaller screens */
@media (max-width: 768px) {
  .detail-box h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  /* Adjust space between header and section for smaller screens */
  .hero_area {
    margin-top: 20px;
  }

  .about_section {
    padding-top: 20px;
  }

  .static-image {
    width: 30%; /* Increase logo size for smaller screens */
  }
}

/* Adjust layout for larger screens */
@media (min-width: 992px) {
  .about_section .row {
    display: flex;
    align-items: center;
  }
}

/* Ensure the image and text stack on smaller screens */
@media (max-width: 991px) {
  .detail-box {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }

  .static-image {
    width: 90%; /* Increase logo size to 50% on mobile devices */
  }
}
