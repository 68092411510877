/* Styling for the back arrow */
.back-arrow {
    position: absolute;
    top: 50px;  /* Positioned just below the red header */
    left: 20px; /* Positioned to the left side */
    z-index: 10;
    text-decoration: none;
  }
  
  .arrow-icon {
    font-size: 40px; /* Increased size of the arrow */
    color: red;  /* Red color for the arrow */
    cursor: pointer;
    transition: color 0.3s, transform 0.3s;
  }
  
  .arrow-icon:hover {
    color: darkred; /* Darker red color on hover */
    transform: translateY(-2px); /* Slight lift effect */
  }
  
  /* General container styling */
  .sanitar-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
  }
  
  .image-gallery {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }
  
  .responsive-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  /* Modal specific styling */
  .modal-content {
    width: 100%; /* Make modal content take full width */
    max-width: 1000px; /* Increase max-width for larger modals */
    margin: 0 auto;
  }
  
  /* Make the modal bigger */
  .modal-body .row {
    display: flex;
    justify-content: center;
  }
  
  /* Image styling inside modal */
  .modal-body .row img {
    width: 100%;  /* Allow images to fill the modal */
    height: auto;
    display: block;
    margin: 0 auto; /* Center the images */
    border-radius: 10px;
    max-width: 100%; /* Allow images to take full width in modal */
  }
  
  /* Optional: increase size of images in modal */
  @media (max-width: 768px) {
    .modal-body .row img {
      width: 95%; /* Adjust size for mobile screens */
    }
  }
  
  @media (min-width: 768px) {
    .modal-body .row img {
      width: 90%; /* Adjust size for larger screens */
    }
  }
  
  /* Styling for modal header and footer */
  .modal-header .modal-title {
    text-align: center;
  }
  
  .modal-footer {
    display: flex;
    justify-content: center;
    border-top: none; /* Remove border in footer */
  }
  
  /* Ensure modal is responsive */
  @media (max-width: 768px) {
    .modal-content {
      width: 95%; /* Make modal more responsive on mobile */
    }
  }
  