@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Merriweather+Sans:wght@400;700&display=swap");


h1,
h2 {
  font-family: 'Merriweather Sans', sans-serif;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.slider_section .detail-box h1 {
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 25px;
  color: #080909;
}

.slider_section .detail-box p {
  color: #444;
  font-size: 17px;
}