/* Services.css */
.services {
  background-color: #007bff;
    padding: 2rem 1rem;
    text-align: center;
  }
  
  .services-content {
    background-color: #007bff;
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
  }
 /* Services.css */

/* Existing styles... */

.img-box img {
  width: 200px; 
  height: 20px; 
  object-fit: fill; 
}

.img-box1 img {
  width: 230px; 
  height: 230px; 
  object-fit: cover; 
}


.modal-body img {
  width: 100%; 
  height: auto;
  max-width: 600px; 
  margin: 0 auto; 
}

.custom-modal .modal-dialog {
  max-width: 90%;
  width: 90%; 
}

.custom-modal .modal-body {
  max-height: 80vh;
  overflow-y: auto;
}

.custom-modal .img-fluid {
  margin-top: 15px;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-title-center {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

