/* contactUs.css */

.contact_section {
    padding: 50px 0;
    background-color: #f4f7fa;
  }
  
  .contact_section .heading_container {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .contact_section .heading_container h2 {
    font-size: 2.5rem;
    font-family: 'Merriweather', serif;
    color: #333;
  }
  
  .contact_section form {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact_section form .form-control {
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 1rem;
    border: 1px solid #ddd;
  }
  
  .contact_section form .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
  
  .contact_section form textarea {
    resize: none;
  }
  
  .contact_section form button {
    background-color: #007bff;
    color: white;
    font-size: 1.1rem;
    border-radius: 4px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  .contact_section form button:hover {
    background-color: #0056b3;
  }
  
  .contact_section .email_status {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2rem;
    color: green;
  }
  
  .contact_section .email_status.error {
    color: red;
  }
  
  /* Shtimi i hartës */
  .map_section {
    background-color: #ffffff;
    padding: 30px 0;
  }
  
  .map-container {
    text-align: center;
  }
  
  /* Rishikimet e klientëve */
  .reviews_section {
    background-color: #f9f9f9;
  }
  
  .review-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .review-box p {
    font-size: 1rem;
    color: #555;
  }
  
  .review-box h5 {
    font-weight: bold;
    color: #333;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .contact_section .heading_container h2 {
      font-size: 2rem;
    }
  
    .contact_section form {
      padding: 20px;
    }
  }
  